var youtubeThumb = function(id) {
    var thumb = '<img data-src="https://i.ytimg.com/vi/ID/maxresdefault.jpg" alt="Youtube thumbnail" class="YT-thumbnail lazy">',
    play = '<div class="play"></div>';
    return thumb.replace('ID', id) + play;
}

var youtubeIframe = function() {
    var iframe = document.createElement('iframe');
    var embed = 'https://www.youtube.com/embed/ID?autoplay=1&rel=0';
    iframe.setAttribute('src', embed.replace('ID', this.dataset.id));
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '1');
    this.parentNode.replaceChild(iframe, this);
}

document.addEventListener("DOMContentLoaded", function(event) {
    // lazy load YouTube videos
    var div,
    n,
    v = document.getElementsByClassName('youtube-embed');

    for (n = 0; n < v.length; n++) {
        div = document.createElement('div');
        div.setAttribute('data-id', v[n].dataset.vidId);
        div.innerHTML = youtubeThumb(v[n].dataset.vidId);
        div.onclick = youtubeIframe;
        v[n].appendChild(div);
    }

    let check_iterations = 0;
    const max_attempts = 10;

    function updateLazyLoad() {
        if (typeof lazyLoadInstance === 'object') {
            console.log('Updating Lazy Load')
            lazyLoadInstance.update();
        } 
        else {
            if (check_iterations < max_attempts) setTimeout(updateLazyLoad, 250);

            check_iterations++;
        }
    }

    updateLazyLoad();
});
